.border-none[data-v-214d8cc5]:before {
  display: none;
}
.border-none[data-v-214d8cc5]:after {
  display: none;
}
.flex-fix[data-v-214d8cc5] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-214d8cc5]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-214d8cc5] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-214d8cc5] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-214d8cc5] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-214d8cc5] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-214d8cc5] {
  *zoom: 1;
}
.clear-fix[data-v-214d8cc5]:before,
.clear-fix[data-v-214d8cc5]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.recode[data-v-214d8cc5] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.recode .list-top[data-v-214d8cc5] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 0.88rem;
  background: #fff;
  position: fixed;
  top: 0;
  z-index: 999;
}
.recode .list-top .list-item[data-v-214d8cc5] {
  line-height: 0.88rem;
  font-size: 0.28rem;
  color: #7d7d7d;
  text-align: center;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  position: relative;
}
.recode .list-top .active[data-v-214d8cc5] {
  font-size: 0.28rem;
  color: #333333;
  font-weight: bold;
}
.recode .list-top .active[data-v-214d8cc5]::after {
  content: "";
  position: absolute;
  width: 0.5rem;
  height: 2px;
  background: var(--main-color);
  bottom: 0;
  left: 50%;
  margin-left: -0.25rem;
}
.recode .list-cont[data-v-214d8cc5] {
  padding-top: 0.88rem;
}
.recode .list-cont .list-layout[data-v-214d8cc5] {
  margin: 0.2rem;
  background: #fff;
}
.recode .list-cont .list-layout .overall-layout[data-v-214d8cc5] {
  margin: 0.2rem;
  padding: 0.2rem 0;
  height: 100%;
}
.recode .list-cont .list-layout .overall-layout .layout-top[data-v-214d8cc5] {
  width: 100%;
  font-size: 0.32rem;
  color: #333333;
  background: white url(../../../assets/icon_detail@3x.png) no-repeat 100% 50%;
  background-size: 0.4rem 0.4rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.recode .list-cont .list-layout .overall-layout .layout-top .time[data-v-214d8cc5] {
  font-size: 0.24rem;
  color: #999;
}
.recode .list-cont .list-layout .overall-layout .layout-top .status[data-v-214d8cc5] {
  font-size: 0.24rem;
  color: var(--main-color);
}
.recode .list-cont .list-layout .overall-layout .layout-bottom[data-v-214d8cc5] {
  margin-top: 0.4rem;
  height: 1.2rem;
  position: relative;
  overflow: hidden;
}
.recode .list-cont .list-layout .overall-layout .layout-bottom .img[data-v-214d8cc5] {
  float: left;
}
.recode .list-cont .list-layout .overall-layout .layout-bottom .img img[data-v-214d8cc5] {
  width: 0.96rem;
  height: 0.96rem;
}
.recode .list-cont .list-layout .overall-layout .layout-bottom .use-point[data-v-214d8cc5] {
  float: right;
  padding-top: 0.09rem;
  font-size: 0.28rem;
  color: #666;
}
.recode .list-cont .list-layout .overall-layout .layout-bottom .img-txt[data-v-214d8cc5] {
  width: 3.4rem;
  height: 0.96rem;
  margin-left: 0.2rem;
  float: left;
  position: relative;
}
.recode .list-cont .list-layout .overall-layout .layout-bottom .img-txt .number[data-v-214d8cc5] {
  font-size: 0.24rem;
  color: #999;
  position: absolute;
  bottom: 0.05rem;
}
.recode .list-cont .list-layout .overall-layout .layout-bottom .img-txt .name[data-v-214d8cc5] {
  font-size: 0.28rem;
  color: #333;
  padding-top: 0.09rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.recode .list-cont .list-layout .overall-layout .order-btn[data-v-214d8cc5] {
  overflow: hidden;
}
.recode .list-cont .list-layout .overall-layout .order-btn .common[data-v-214d8cc5] {
  float: right;
  margin-top: 0.2rem;
  width: 2rem;
  height: 0.68rem;
  border-radius: 0.68rem;
  text-align: center;
  line-height: 0.68rem;
  font-size: 0.28rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  color: var(--main-color);
  border: 1px solid var(--main-color);
  margin-right: 0.3rem;
}
.recode .list-cont .list-layout .overall-layout .order-btn .concel[data-v-214d8cc5] {
  color: #666;
}
.recode .list-cont .list-layout .overall-layout .order-btn .confirm[data-v-214d8cc5] {
  background: var(--main-color);
  color: #fff;
}
