.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.recode {
  box-sizing: border-box;
}
.recode .list-top {
  display: flex;
  align-items: center;
  width: 100%;
  height: 0.88rem;
  background: #fff;
  position: fixed;
  top: 0;
  z-index: 999;
}
.recode .list-top .list-item {
  line-height: 0.88rem;
  font-size: 0.28rem;
  color: #7d7d7d;
  text-align: center;
  flex: 1;
  position: relative;
}
.recode .list-top .active {
  font-size: 0.28rem;
  color: #333333;
  font-weight: bold;
}
.recode .list-top .active::after {
  content: "";
  position: absolute;
  width: 0.5rem;
  height: 2px;
  background: var(--main-color);
  bottom: 0;
  left: 50%;
  margin-left: -0.25rem;
}
.recode .list-cont {
  padding-top: 0.88rem;
}
.recode .list-cont .list-layout {
  margin: 0.2rem;
  background: #fff;
}
.recode .list-cont .list-layout .overall-layout {
  margin: 0.2rem;
  padding: 0.2rem 0;
  height: 100%;
}
.recode .list-cont .list-layout .overall-layout .layout-top {
  width: 100%;
  font-size: 0.32rem;
  color: #333333;
  background: white url(../../../assets/icon_detail@3x.png) no-repeat 100% 50%;
  background-size: 0.4rem 0.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.recode .list-cont .list-layout .overall-layout .layout-top .time {
  font-size: 0.24rem;
  color: #999;
}
.recode .list-cont .list-layout .overall-layout .layout-top .status {
  font-size: 0.24rem;
  color: var(--main-color);
}
.recode .list-cont .list-layout .overall-layout .layout-bottom {
  margin-top: 0.4rem;
  height: 1.2rem;
  position: relative;
  overflow: hidden;
}
.recode .list-cont .list-layout .overall-layout .layout-bottom .img {
  float: left;
}
.recode .list-cont .list-layout .overall-layout .layout-bottom .img img {
  width: 0.96rem;
  height: 0.96rem;
}
.recode .list-cont .list-layout .overall-layout .layout-bottom .use-point {
  float: right;
  padding-top: 0.09rem;
  font-size: 0.28rem;
  color: #666;
}
.recode .list-cont .list-layout .overall-layout .layout-bottom .img-txt {
  width: 3.4rem;
  height: 0.96rem;
  margin-left: 0.2rem;
  float: left;
  position: relative;
}
.recode .list-cont .list-layout .overall-layout .layout-bottom .img-txt .number {
  font-size: 0.24rem;
  color: #999;
  position: absolute;
  bottom: 0.05rem;
}
.recode .list-cont .list-layout .overall-layout .layout-bottom .img-txt .name {
  font-size: 0.28rem;
  color: #333;
  padding-top: 0.09rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.recode .list-cont .list-layout .overall-layout .order-btn {
  overflow: hidden;
}
.recode .list-cont .list-layout .overall-layout .order-btn .common {
  float: right;
  margin-top: 0.2rem;
  width: 2rem;
  height: 0.68rem;
  border-radius: 0.68rem;
  text-align: center;
  line-height: 0.68rem;
  font-size: 0.28rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  color: var(--main-color);
  border: 1px solid var(--main-color);
  margin-right: 0.3rem;
}
.recode .list-cont .list-layout .overall-layout .order-btn .concel {
  color: #666;
}
.recode .list-cont .list-layout .overall-layout .order-btn .confirm {
  background: var(--main-color);
  color: #fff;
}
